import $ from 'jquery';
import jQuery from 'jquery';
import Swal from 'sweetalert2';
import * as d3 from 'd3';

export const showUserMessage = function (title, message, type, complete) {

    if (type == 1) {

        Swal.fire({
            title: title,
            text: message,
            icon: "success"
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

	}else if (type == 2){

        Swal.fire({
            title: title,
            text: message,
            icon: "warning"
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

    } else {

        Swal.fire({
            title: title,
            text: message,
            icon: "error"
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

    }

    return true;

}

export const showUserMessageHTML = function ( title , message , type , complete ) {

	if (type == 1) {

        Swal.fire({
            title: title,
            html: message,
            icon: "success",
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

	}else if (type == 2){

        Swal.fire({
            title: title,
            html: message,
            icon: "warning"
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

	}else{

        Swal.fire({
            title: title,
            html: message,
            icon: "error"
        }).then(() => {
            if (typeof complete == 'function')
                complete();
        });

	}

	return true;

}


export const showMessage = function (object, message, mobile_only) {

    object.find('.nNote').remove();
    object.prepend('<div class="nNote alert alert-danger alert-styled-left alert-bordered" style="display:none;">' + message + '</div>');
    object.find('.nNote').slideDown();
}

export const removeMessage = function (object) {
    object.find('.nNote').slideUp(function() {
        $(this).remove();
    });
}


export const getGetParam = function (param){
	return (window.location.search.split( param + '=')[1] || '').split('&')[0];
}

// Chart setup
function campaignDonut(element, size) {


    // Basic setup
    // ------------------------------

    // Add data set

    var data = $(element).data('src');



    // Main variables
    var d3Container = d3.select(element),
        distance = 2, // reserve 2px space for mouseover arc moving
        radius = (size / 2) - distance,
        sum = d3.sum(data, function (d) {
            return d.value;
        })


    // Tooltip
    // ------------------------------

    var tip = d3.tip()
        .attr('class', 'd3-tip')
        .offset([-10, 0])
        .direction('e')
        .html(function (d) {
            return "<ul class='list-unstyled mb-5'>" +
                "<li>" + "<div class='text-size-base mb-5 mt-5'>" + d.data.text + ': ' + "<span class='text-semibold'>" + d.value + "</span>" + "</div>" + "</li>" +
                "</ul>";
        })



    // Create chart
    // ------------------------------

    // Add svg element
    var container = d3Container.append("svg").call(tip);

    // Add SVG group
    var svg = container
        .attr("width", size)
        .attr("height", size)
        .append("g")
        .attr("transform", "translate(" + (size / 2) + "," + (size / 2) + ")");



    // Construct chart layout
    // ------------------------------

    // Pie
    var pie = d3.layout.pie()
        .sort(null)
        .startAngle(Math.PI)
        .endAngle(3 * Math.PI)
        .value(function (d) {
            return d.value;
        });

    // Arc
    var arc = d3.svg.arc()
        .outerRadius(radius)
        .innerRadius(radius / 2);



    //
    // Append chart elements
    //

    // Group chart elements
    var arcGroup = svg.selectAll(".d3-arc")
        .data(pie(data))
        .enter()
        .append("g")
        .attr("class", "d3-arc")
        .style('stroke', '#fff')
        .style('cursor', 'pointer');

    // Append path
    var arcPath = arcGroup
        .append("path")
        .style("fill", function (d) {
            return d.data.color;
        });

    // Add tooltip
    arcPath
        .on('mouseover', function (d, i) {

            // Transition on mouseover
            d3.select(this)
                .transition()
                .duration(500)
                .ease('elastic')
                .attr('transform', function (d) {
                    d.midAngle = ((d.endAngle - d.startAngle) / 2) + d.startAngle;
                    var x = Math.sin(d.midAngle) * distance;
                    var y = -Math.cos(d.midAngle) * distance;
                    return 'translate(' + x + ',' + y + ')';
                });
        })

        .on("mousemove", function (d) {

            // Show tooltip on mousemove
            tip.show(d)
                .style("top", (d3.event.pageY - 40) + "px")
                .style("left", (d3.event.pageX + 30) + "px");
        })

        .on('mouseout', function (d, i) {

            // Mouseout transition
            d3.select(this)
                .transition()
                .duration(500)
                .ease('bounce')
                .attr('transform', 'translate(0,0)');

            // Hide tooltip
            tip.hide(d);
        });

    // Animate chart on load
    arcPath
        .transition()
        .delay(function (d, i) {
            return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
            var interpolate = d3.interpolate(d.startAngle, d.endAngle);
            return function (t) {
                d.endAngle = interpolate(t);
                return arc(d);
            };
        });
}


const initApplicationDefaults = () => {
    $(function () {

        //$("body").css('display','block');

        $('.tabs a[data-toggle="tab"]').on('shown', function (e) {
            $(window).resize();
        });

        $(".graph-donut").each(function (i, v) {
            campaignDonut(v, $(v).data('size'));
        });

        if (window.matchMedia('(max-width: 1300px) and (min-width:1200px)').matches){
            if (!$("body").hasClass('sidebar-xs')){
                $("body").addClass('sidebar-xs');
            }
        }

        $("form:not(.generic_form) .zend_form > div:not(.full)").addClass('span6');
        $("form:not(.generic_form) .zend_form > div.full").next('.span6').css('margin-left', '0px');
        $("form:not(.generic_form) .zend_form").append('<div class="clear"></div>');
        $("form.generic_form .zend_form > div:not(.full)").removeClass('span6');
        $("form:not(.generic_form) .zend_form > .full").before('<div class="clear"></div>');

        // $(window).load(function () {
        if ($(".navbar .btn-navbar").css("display") == "none") {
            //$(".sliding-collapse").next('.span12').addClass("slid-content");
        } else {
            $(".sliding-collapse").addClass("collapse width");
        }
        // });

        $(window).resize(function () {
            if ($(".navbar .btn-navbar").css("display") == "none") {
                //$(".sliding-collapse").next('.span12').addClass("slid-content");
                $(".sliding-collapse").removeClass("collapse width");
            } else {
                //$(".sliding-collapse").next('.span12').removeClass("slid-content");
                $(".sliding-collapse").addClass("collapse width");
            }
            if (window.matchMedia('(max-width: 1300px) and (min-width:1200px)').matches){
                if (!$("body").hasClass('sidebar-xs')){
                    $("body").addClass('sidebar-xs');
                }
            }
        });

        var url = document.location.toString();
        if (url.match('#')) {
            $('.tabs a[href=\'#' + url.split('#')[1] + '\']').tab('show');
        }

        function showDialog(title, message, buttons) {
            $("#CommErrorLabel").html(title);
            $("#comm_error .modal-body").html(message);
            // $("#comm_error").modal(); TODO: check this out
        }


        $(document).ajaxError(function (e, xhr, settings, exception) {
            showDialog('Communication Error', 'An error occured while communicating with the server.<br /><br />Please try again.');
        });


        jQuery.fn.MultiSelectBox = function () {



        };



        // Initialize plugins
        // ------------------------------
        if (typeof $('.select').select2 === 'function') { // TODO: Check initialisation process
            $('.select').select2()
                .on('change', function () {
                    if ($(this).attr('id') && $(this).attr('id').indexOf("category_") !== -1) {
                        $(this).closest('.form-group').find('.text-success').remove();
                        if ($(this).val()) {
                            $(this).closest('.form-group').find('label').append('<span class="text-success icon-checkmark-circle"></span>');
                        }
                    }
                });
        }


        // Fixed width. Single select
        if (typeof $('.select-fixed-single').select2 === 'function') {
            $('.select-fixed-single').select2({
                width: 250
            })
                .on('change', function () {

                    if ($(this).attr('id').indexOf("category_") !== -1) {
                        $(this).closest('.form-group').find('.text-success').remove();
                        if ($(this).val()) {
                            $(this).closest('.form-group').find('label').append('<span class="text-success icon-checkmark-circle"></span>');
                        }
                    }
                });
        }


        $("select.multiselect-allow-all").each(function(i,v){
            $(v).data('previous',$(v).val());
        });

        $(".multiselect-allow-all").on('change',function(){

            var current     = $(this).val();
            var previous    = $(this).data('previous');
            if (previous != null){
                if (current.length > 0 && previous.length > 0){
                    if (current.includes("0") && !previous.includes("0")){
                        $(this).val([0]);
                        $(this).multiselect('refresh');
                        $.uniform.update();
                    }else if (current.includes("0") && current.length > 1 && previous.includes("0")){
                        var zero_index = current.indexOf("0");
                        if (zero_index > -1) {
                            current.splice(zero_index, 1);
                        }
                        $(this).val(current);
                        $(this).multiselect('refresh');
                        $.uniform.update();
                    }
                }
            }
            $(this).data('previous',$(this).val());
        });

        jQuery.fn.addPlaceholderSupport = function () {

            var _debug = false;
            var _placeholderSupport = function () {
                var t = document.createElement("input");
                t.type = "text";
                return (typeof t.placeholder !== "undefined");
            }();

            window.onload = function () {
                var arrInputs = document.getElementsByTagName("input");
                var arrTextareas = document.getElementsByTagName("textarea");
                var combinedArray = [];
                for (var i = 0; i < arrInputs.length; i++)
                    combinedArray.push(arrInputs[i]);
                for (var i = 0; i < arrTextareas.length; i++)
                    combinedArray.push(arrTextareas[i]);
                for (var i = 0; i < combinedArray.length; i++) {
                    var curInput = combinedArray[i];
                    if (!curInput.type || curInput.type == "" || curInput.type == "text" || curInput.type == "textarea")
                        HandlePlaceholder(curInput);
                    else if (curInput.type == "password")
                        ReplaceWithText(curInput);
                }

                if (!_placeholderSupport) {
                    for (var i = 0; i < document.forms.length; i++) {
                        var oForm = document.forms[i];
                        if (oForm.attachEvent) {
                            oForm.attachEvent("onsubmit", function () {
                                PlaceholderFormSubmit(oForm);
                            });
                        } else if (oForm.addEventListener)
                            oForm.addEventListener("submit", function () {
                                PlaceholderFormSubmit(oForm);
                            }, false);
                    }
                }
            };

            function PlaceholderFormSubmit(oForm) {
                for (var i = 0; i < oForm.elements.length; i++) {
                    var curElement = oForm.elements[i];
                    HandlePlaceholderItemSubmit(curElement);
                }
            }

            function HandlePlaceholderItemSubmit(element) {
                if (element.name) {
                    var curPlaceholder = element.getAttribute("placeholder");
                    if (curPlaceholder && curPlaceholder.length > 0 && element.value === curPlaceholder) {
                        element.value = "";
                        window.setTimeout(function () {
                            element.value = curPlaceholder;
                        }, 100);
                    }
                }
            }

            function ReplaceWithText(oPasswordTextbox) {
                if (_placeholderSupport)
                    return;
                var oTextbox = document.createElement("input");
                oTextbox.type = "text";
                oTextbox.id = oPasswordTextbox.id;
                oTextbox.name = oPasswordTextbox.name;
                //oTextbox.style = oPasswordTextbox.style;
                oTextbox.className = oPasswordTextbox.className;
                for (var i = 0; i < oPasswordTextbox.attributes.length; i++) {
                    var curName = oPasswordTextbox.attributes.item(i).nodeName;
                    var curValue = oPasswordTextbox.attributes.item(i).nodeValue;
                    if (curName !== "type" && curName !== "name") {
                        oTextbox.setAttribute(curName, curValue);
                    }
                }
                oTextbox.originalTextbox = oPasswordTextbox;
                oPasswordTextbox.parentNode.replaceChild(oTextbox, oPasswordTextbox);
                HandlePlaceholder(oTextbox);
                if (!_placeholderSupport) {
                    oPasswordTextbox.onblur = function () {
                        if (this.dummyTextbox && this.value.length === 0) {
                            this.parentNode.replaceChild(this.dummyTextbox, this);
                        }
                    };
                }
            }

            function HandlePlaceholder(oTextbox) {
                if (!_placeholderSupport) {
                    var curPlaceholder = oTextbox.getAttribute("placeholder");
                    if (curPlaceholder && curPlaceholder.length > 0) {
                        Debug("Placeholder found for input box '" + oTextbox.name + "': " + curPlaceholder);
                        oTextbox.value = curPlaceholder;
                        oTextbox.setAttribute("old_color", oTextbox.style.color);
                        oTextbox.style.color = "#c0c0c0";
                        oTextbox.onfocus = function () {
                            var _this = this;
                            if (this.originalTextbox) {
                                _this = this.originalTextbox;
                                _this.dummyTextbox = this;
                                this.parentNode.replaceChild(this.originalTextbox, this);
                                _this.focus();
                            }
                            Debug("input box '" + _this.name + "' focus");
                            _this.style.color = _this.getAttribute("old_color");
                            if (_this.value === curPlaceholder)
                                _this.value = "";
                        };
                        oTextbox.onblur = function () {
                            var _this = this;
                            Debug("input box '" + _this.name + "' blur");
                            if (_this.value === "") {
                                _this.style.color = "#c0c0c0";
                                _this.value = curPlaceholder;
                            }
                        };
                    } else {
                        Debug("input box '" + oTextbox.name + "' does not have placeholder attribute");
                    }
                } else {
                    Debug("browser has native support for placeholder");
                }
            }

            function Debug(msg) {
                if (typeof _debug !== "undefined" && _debug) {
                    var oConsole = document.getElementById("Console");
                    if (!oConsole) {
                        oConsole = document.createElement("div");
                        oConsole.id = "Console";
                        document.body.appendChild(oConsole);
                    }
                    oConsole.innerHTML += msg + "<br />";
                }
            }

        };


        jQuery.fn.blockButton = function () {

            var $this = this;

            $this.after('<div class="button_disabled" style="cursor: wait; top:0px;opacity:0.6;background:#fff;left:0px;height:' + $this.outerHeight() + 'px;right:0px;position:absolute;"></div>');

            $this.after('<span class="form_loading">&nbsp;</span>')

        };


        jQuery.fn.unblockButton = function () {

            var $this = this;

            $this.next('.form_loading').remove();
            $this.next('.button_disabled').remove();

        };

        jQuery.fn.resetForm = function () {
            var $this = this;
            $this[0].reset();
            this.find('select').trigger('change');
            $this.find('.nNote').remove();
        };

        jQuery.fn.processForm = function (options, selector) {

            var $this = this;

            if ($this.find('button[type="submit"]').length >= 1) {
                if ($this.find('button[type="submit"]').text() != 'Please wait...'){
                    $this.find('button[type="submit"]').data('caption', $this.find('button[type="submit"]').text());
                    $this.find('button[type="submit"]').text('Please wait...');
                }
                $this.find('button[type="submit"]').blockButton();
            } else if ($this.closest('.modal').find('button.modal_submit[type="button"]').length >= 1) {
                if ($this.closest('.modal').find('button.modal_submit[type="button"]').text() != 'Please wait...'){
                    $this.closest('.modal').find('button.modal_submit[type="button"]').data('caption', $this.closest('.modal').find('button.modal_submit[type="button"]').text());
                    $this.closest('.modal').find('button.modal_submit[type="button"]').text('Please wait...');
                }
                $this.closest('.modal').find('button.modal_submit[type="button"]').blockButton();
            } else if ($this.find('button[type="button"]').length >= 1) {
                if ($this.find('button[type="button"]').text() != 'Please wait...') {
                    $this.find('button[type="button"]').data('caption', $this.find('button[type="button"]').text());
                    $this.find('button[type="button"]').text('Please wait...');
                }
                $this.find('button[type="button"]').blockButton();
            }


            if (typeof options == "undefined") {
                options = {
                    mode: "redirect",
                    source: null,
                    service: $(this).attr('action'),
                    callback: '',
                    data_return: ''
                };
            }

            if (typeof options.mode == "undefined") {
                options.mode = "redirect";
            }

            if (typeof options.source == "undefined") {
                options.source = null;
            }


            if (typeof options.service == "undefined") {
                options.service = $(this).attr('action');
            }

            if (typeof options.callback == "undefined") {
                options.callback = '';
            }

            if (typeof options.data_return == "undefined") {
                options.data_return = '';
            }


            $.post(options.service, $this.serializeArray(), function (response) {

                validateAJAX(response, options.mode, options.source, options.callback, options.data_return, $this);

            }, 'json');

            return true;

        };

// AROSHA: Commenting unused function
        // function populateAJAX(response, form_class) {
        //
        // 	$('form.' + form_class + ' *').removeClass('error');
        // 	$('.messages').remove();
        // 	$('form.' + form_class + ' *').attr('title', "")
        // 		.attr('original-title', "");
        //
        // 	$.each(response, function (k, v) {
        // 		$("#" + k).val(v);
        // 	});
        //
        // 	if (typeof afterAJAXFormPopulate == 'function') {
        // 		afterAJAXFormPopulate();
        // 	}
        // }

        function validateAJAX(response, mode, source, callback, data_return, object) {

            $('.errors, .messages').remove();
            removeMessage(object);

            if (object.find('button[type="submit"]').length >= 1) {
                object.find('button[type="submit"]').text(object.find('button[type="submit"]').data('caption'));
                object.find('button[type="submit"]').unblockButton();
            } else if (object.closest('.modal').find('button.modal_submit[type="button"]').length >= 1) {
                object.closest('.modal').find('button.modal_submit[type="button"]').text(object.closest('.modal').find('button.modal_submit[type="button"]').data('caption'));
                object.closest('.modal').find('button.modal_submit[type="button"]').unblockButton();
            } else if (object.find('button[type="button"]').length >= 1) {
                object.find('button[type="button"]').text(object.find('button[type="button"]').data('caption'));
                object.find('button[type="button"]').unblockButton();
            }

            if (response.valid) {
                if (mode == "redirect") {
                    window.location = response.redirect;
                    return true;
                } else if (mode == "return" || mode == "form_return") {

                    if (typeof (response.info.title) != "undefined") {
                        showUserMessage(response.info.title, response.info.message, 1, function () {
                            window.location = response.redirect;
                            return true;
                        });
                    }

                    return true;
                } else if (mode == "callback") {
                    if (typeof (callback) === "function") {
                        callback(true,response);
                    }
                    return true;
                } else if (mode == "data_return") {
                    if (typeof (data_return) === "function") {
                        data_return(true, response);
                    }
                    return true;
                } else {
                    source.dialog('close');
                    return true;
                }
            }

            var error_message = '';

            $(response.error.html_message).each(function (i, message) {
                error_message = error_message + message;
            });

            if (error_message != '') {

                showMessage(object, error_message);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            $(response.error.element).each(function (i, dataset) {
                if (dataset.messages.length) {
                    $('#' + dataset.field).attr("title", function () {
                        var render = '';

                        $(dataset.messages).each(function (i, message) {
                            render = render + message;
                        });

                        return render;
                    });

                    $('#' + dataset.field).addClass('error');
                }
            });

            if (mode == "callback") {
                if (typeof (callback) === "function") {
                    callback(false,response);
                }

                return false;
            }

            if (mode == "data_return") {
                if (typeof (data_return) === "function") {
                    data_return(false, response);
                }

                return false;
            }

            return false;
        }
    });


    /* JqGrid Generic Action */
    $(function () {
        jQuery.fn.gridAction = function (grid_id, options) {
            var $this = this;

            $(this).click(function () {

                if ($this.attr('href') == '' || $this.attr('href') == '#') return false;

                var selected = $(grid_id).jqGrid('getGridParam', 'selarrrow');

                if (options.selectable) {

                    var message = null;

                    if (options.multiple && selected.length == 0)
                        message = 'Please select at least one record.';

                    if (!options.multiple && selected.length == 0)
                        message = 'Please select one record.';

                    if (!options.multiple && selected.length > 1)
                        message = 'You can perform this action on only one record.';

                    if (message) {

                        showUserMessage('Records', message, 0);
                        return false;
                    }

                }

                if (options.confirm) {

                    console.log(options.confirm);

                    if (window.confirm('Are you sure you want to perform the selected action?')) {

                        executeAction($this);
                        return false;
                    }

                    return false;

                } else {
                    executeAction($this);
                }


                function executeAction($this) {
                    if (options.multiple) {
                        $this.blockButton();

                        for (let i = 0; i < selected.length; i++)
                            $.post($this.attr('href'), {'id': selected[i]},
                                function () {
                                    $(grid_id).trigger("reloadGrid");
                                });

                        $this.unblockButton();

                        return false;
                    }

                    if ($this.attr('href') != '')
                        window.location = $this.attr('href') + '?id=' + selected[0];

                    return false;
                }

                return false;
            });
        };

    });


    /* Header Bar Buttons */
    $(function () {
        jQuery.fn.addTitleBarButton = function (p) {
            var $this = this;

            var icon = '';
            var text = '';
            var link = '';
            var wrapper = '';

            if (p.icon) {
                icon = jQuery('<i>', {class: 'visible-phone icon icon-' + p.icon});
            }

            if (p.text) {
                text = jQuery('<span>', {class: 'hidden-phone'}).append(p.text);
            } else {
                p.text = '';
            }

            link = jQuery('<a>', {class: p.class, href: '#', title: p.text});
            link.append(icon, text);

            if (p.onClick) {
                if (typeof (p.onClick) == "function") {
                    link.click(function () {
                        p.onClick();
                    });
                }
            }

            wrapper = jQuery('<div>', {class: 'titleOpt'}).append(link);
            jQuery(this).append(wrapper);

        };

    });


    /* Multi Select Box */
    $(function () {
        jQuery.fn.MultiSelectDropDown = function () {
            var $this = $(this);

            $this.multiselect({
                buttonText: function (options, select) {

                    if (options.length == 0) {
                        return '<i class="pull-right icon icon-chevron-down"></i> ' + this.nonSelectedText;
                    } else {
                        if (options.length > this.numberDisplayed) {
                            return '<i class="pull-right icon icon-chevron-down"></i> ' + options.length + ' ' + this.nSelectedText;
                        } else {
                            var selected = '';
                            options.each(function () {
                                var label = ($(this).attr('label') !== undefined) ? $(this).attr('label') : $(this).html();
                                selected += label + ', ';
                            });

                            return '<i class="pull-right icon icon-chevron-down"></i> ' + selected.substr(0, selected.length - 2);
                        }
                    }
                },
                maxHeight: 250,
                buttonContainer: '<div class="span12 btn-group multi-dropdown" />',
                buttonClass: 'span12 btn multi-button'
            });
        };

    });
};

export default initApplicationDefaults;
