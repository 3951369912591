import React from 'react'
import { formatDate } from '../BirthdayPartyBooking'

const BookingInfo = ({userData, checkoutData}) => {
    const convertDate= (encodedString) => {
        const decodedString = atob(encodedString);
        const decodedArray = decodedString.split('|');
        const date = new Date(decodedArray[2]);
        const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <>
            <fieldset>
                <h2>Confirm Your Booking Details</h2>

                <div className="row">
                    <p><label className="col-sm-3">Parent's Name:</label> <span
                        className="col-sm-9">{userData.full_name}</span>
                    </p>
                    <div className="clearfix"><br/></div>
                    <p><label className="col-sm-3">Email Address:</label> <span
                        className="col-sm-9">{userData.email}</span></p>
                    
                    <div className="clearfix"><br/></div>
                    <p><label className="col-sm-3">Phone:</label> <span
                        className="col-sm-9">{userData.phone}</span></p>
                    
                    <div className="clearfix"><br/></div>
                    {(checkoutData.time && checkoutData.date) && (
                        <>
                            <p><label className="col-sm-3">Booking:</label>
                                <span className="col-sm-9">
                            On {formatDate(checkoutData.date, 'long')}, from {checkoutData.time}
                        </span>
                            </p>
                            
                    <div className="clearfix"><br/></div>
                        </>
                    )}
                    {checkoutData.event_name && (
                        <>
                            <p><label className="col-sm-3">Event:</label> <span
                                className="col-sm-9">{checkoutData.event_name}</span></p>
                            
                    <div className="clearfix"><br/></div>
                        </>
                    )}
                    {checkoutData.quantity && (
                        <>
                            <p><label className="col-sm-3">Estimated number of guests:</label>
                                <span className="col-sm-9">
                            {checkoutData.quantity}
                        </span>
                            </p>
                            
                    <div className="clearfix"><br/></div>
                        </>
                    )}
                    {checkoutData.booked && (
                        <>
                            <p><label className="col-sm-3">Dates Booked:</label>
                                <span className="col-sm-9">
                            {checkoutData.booked.map(booked => {
                                return <p>{convertDate(booked)}</p>;
                            })}
                        </span>
                            </p>
                            
                    <div className="clearfix"><br/></div>
                        </>
                    )}
                    {checkoutData.waiting && (
                        <>
                            <p><label className="col-sm-3">Dates on Waitlist:</label>
                                <span className="col-sm-9">
                            {checkoutData.waiting.map(booked => {
                                return <p>{convertDate(booked)}</p>;
                            })}
                        </span>
                            </p>
                            
                    <div className="clearfix"><br/></div>
                        </>
                    )}
                </div>
            </fieldset>
        </>
    )
}

export default BookingInfo;
