import React, { useState, useEffect } from 'react';
import IconCheckBox from './IconCheckBox'

const CAPACITY = 10;
const options = Array.from({ length: CAPACITY }, (_, i) => i + 1);
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // You can add these options to control the display of the currency
    minimumFractionDigits: 2, // (this might be useful for full dollar amounts)
    maximumFractionDigits: 2, // (to avoid many decimal places in prices like $0.9999)
});


const BookingForm = ({ event, availability, bookingFee, quantity, setQuantity, handleCheckboxChange, showCheckoutButton, selectedBooked, selectedWaiting, handleCheckout}) => {
    const [weeks, setWeeks] = useState(null);
    const [weekData, setWeekData] = useState(null);
    const [sessionsByDate, setSessionsByDate] = useState(null);


    // Helper function to add days to a date
    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const isSameDayFunc = (startDateStr, endDateStr) => {
        const startDate = new Date(startDateStr);
        const endDate = new Date(endDateStr);

        const isSameYear = startDate.getFullYear() === endDate.getFullYear();
        const isSameMonth = startDate.getMonth() === endDate.getMonth();
        const isSameDate = startDate.getDate() === endDate.getDate();

        const hoursDifference = Math.abs(startDate.getHours() - endDate.getHours());

        return isSameYear && isSameMonth && isSameDate && (hoursDifference <= 2);
    }

    const encodeSessionData = (id, sessionId, date) => {
        return window.btoa(`${id}|${sessionId}|${date}`);
    };

    // Format date into YYYY-MM-DD
    const formatDateNoTimezone = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const calculateWeeks = () => {
        const start = new Date(event.start_date);
        const end = new Date(event.end_date);

        // Adjust to the first Monday before or equal to start
        start.setDate(start.getDate() - (start.getDay() === 0 ? 6 : start.getDay() - 1));
        // Adjust to the last Sunday after or equal to end
        end.setDate(end.getDate() + (end.getDay() === 0 ? 0 : 7 - end.getDay()));

        let current = new Date(start);
        let calculatedWeeks = [];

        while (current <= end) {
            const weekStart = new Date(current);
            const weekEnd = new Date(current);
            weekEnd.setDate(weekEnd.getDate() + 6);
            calculatedWeeks.push({
                start: formatDateNoTimezone(weekStart),
                end: formatDateNoTimezone(weekEnd)
            });
            current.setDate(current.getDate() + 7);
        }

        setWeeks(calculatedWeeks);
    };

    const mapSessionsToDates = () => {
        const mapping = {};
        event.Sessions.forEach(session => {
            let current = new Date(session.start_date);
            const end = new Date(session.end_date);

            while (current <= end) {
                const dateString = formatDateNoTimezone(current);
                if (!mapping[dateString]) {
                    mapping[dateString] = [];
                }
                mapping[dateString].push(session);
                current.setDate(current.getDate() + 1);
            }
        });

        setSessionsByDate(mapping);
    };

    const processWeeks = () => {
        Object.keys(weeks).forEach(week_id => {
            const week = weeks[week_id];
            for (let i = 0; i <= 6; i++) {
                const current_day = new Date(new Date(week.start).getTime() + i * 86400000).toISOString().slice(0, 10);
                if (sessionsByDate[current_day]) {
                    const sessionName = sessionsByDate[current_day][0].name;
                    if (sessionsByDate[current_day].length === 1 || !weeks[week_id].data?.[sessionName]?.days?.[current_day]) {
                        if (!weeks[week_id].data) weeks[week_id].data = {};
                        if (!weeks[week_id].data[sessionName]) {
                            weeks[week_id].data[sessionName] = {
                                name: sessionName,
                                start_date: sessionsByDate[current_day][0].start_date,
                                end_date: sessionsByDate[current_day][0].end_date,
                                days: { [current_day]: sessionsByDate[current_day][0] }
                            };
                        } else {
                            const existingData = weeks[week_id].data[sessionName];
                            if (new Date(existingData.start_date) > new Date(sessionsByDate[current_day][0].start_date)) {
                                existingData.start_date = sessionsByDate[current_day][0].start_date;
                            }
                            if (new Date(existingData.end_date) < new Date(sessionsByDate[current_day][0].end_date)) {
                                existingData.end_date = sessionsByDate[current_day][0].end_date;
                            }
                            existingData.days[current_day] = sessionsByDate[current_day][0];
                        }
                    }

                    if (sessionsByDate[current_day].length > 1) {
                        sessionsByDate[current_day].forEach((booking_date, booking_index) => {
                            if (booking_index === 0) return;
                            const otherSessionName = booking_date.name;
                            if (!weeks[week_id].data[otherSessionName]) {
                                weeks[week_id].data[otherSessionName] = {
                                    name: otherSessionName,
                                    start_date: booking_date.start_date,
                                    end_date: booking_date.end_date,
                                    days: { [current_day]: booking_date }
                                };
                            } else if (!weeks[week_id].data[otherSessionName].days[current_day]) {
                                weeks[week_id].data[otherSessionName].days[current_day] = booking_date;
                            }
                        });
                    }
                }
            }
        });

        setWeekData([ ...weeks ]);
    }

    useEffect(() => {
        if (!!event) calculateWeeks();
    }, [event]);

    useEffect(() => {
        if (!!weeks && !!event && !!event.Sessions) mapSessionsToDates();
    }, [weeks, event]);

    useEffect(() => {
        if (!weekData && !!weeks && !!event && !!sessionsByDate) processWeeks();
    }, [weeks, event, sessionsByDate]);

    const styles = {
        icon: {
            fontSize: '15px', // Adjust size as necessary
            color: '#ccc',
            backgroundColor: '#efefef',
            padding: '5px',
            borderRadius: '5px', // Optional for rounded corners
            transition: 'all 0.3s', // Smooth transition for color and background
            width: '25px',
            height: '25px',

        },
    };

    const compareDates = (dateStr1, dateStr2, operator) => {
        // Convert strings to Date objects
        const date1 = new Date(dateStr1);
        const date2 = new Date(dateStr2);

        // Create date-only versions to ignore the time component
        const d1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

        // Perform the comparison based on the provided operator
        switch (operator) {
            case '<': return d1 < d2;
            case '<=': return d1 <= d2;
            case '>': return d1 > d2;
            case '>=': return d1 >= d2;
            case '==': return d1.getTime() === d2.getTime();  // Exact date match
            case '!=': return d1.getTime() !== d2.getTime();  // Exact date mismatch
            default: throw new Error("Invalid operator");
        }
    };


    return (
        <form>
            <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="quantity">Quantity for booking:</label>
                        <select id="quantity" className="form-control" value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}>
                            {options.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-9 pt-20">
                        <strong>Note</strong> - <i className={'icon-alarm'} style={styles.icon} /> means the
                        corresponding day is booked out. You can add yourself to the wait list by clicking on the day.
                        You will be notified if a spot becomes available.
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 hidden-sm hidden-xs">&nbsp;</div>
                <div className="col-md-6 no-padding-left no-padding-right">
                    <table className="table">
                        <tr>
                            {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(day => (
                                <td key={day} align="center" style={{width: 100}}>
                                    <strong>{day}</strong>
                                </td>
                            ))}
                        </tr>
                    </table>
                </div>
                <div className="clearfix"></div>
                {weekData && weekData.filter(week => new Date(week.end) >= new Date()).map((week, index) => (
                    week.data && (
                        <div key={index}>
                            <div className="col-md-6 no-padding-left no-padding-right">
                                <table className="table">
                                    <tr className="highlighted-row-tr">
                                        <td className="p-5">Week {index + 1}</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="col-md-6 no-padding-left no-padding-right">
                                <table className="table">
                                    <tr className="highlighted-row-tr">
                                        {[...Array(7).keys()].map(j => {
                                            const weekStartDate = addDays(week.start, j);
                                            const day = weekStartDate.getDate();
                                            const month = weekStartDate.getMonth() + 1;
                                            return (
                                                <td key={j} align="center" className="p-5" style={{width: 100}}>
                                                    {day}/{month}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </table>
                            </div>
                            {Object.values(week.data).map((session, sessionIndex) => (
                                (compareDates(session.start_date, week.end, '<=') && compareDates(session.end_date, week.start, '>=')) && (
                                    <div key={sessionIndex}>
                                        <div className="col-md-6 no-padding-left no-padding-right">
                                            <table className="table ">
                                                <tr className="border-dashed">
                                                    <td style={{padding: "10px 5px 11px 5px"}}>{session.name} ({new Date(session.start_date).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })} to {new Date(session.end_date).toLocaleTimeString('en-US', {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    })})
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="col-md-6 no-padding-left no-padding-right">
                                            <table className="table">
                                                <tr className="border-dashed">
                                                    {[...Array(7).keys()].map(j => {
                                                        const currentDate = addDays(week.start, j);
                                                        const isSameDay = isSameDayFunc(session.start_date, session.end_date)
                                                        const cutoffDate = new Date(event.cut_off_day);
                                                        const isExpired = currentDate.getTime() < new Date().getTime() && ! isSameDay;
                                                        const currentFormattedDate = formatDateNoTimezone(currentDate);
                                                        const sessionData = session.days[currentFormattedDate];
                                                        const sessionStartDate = new Date(session.start_date);

                                                        // Set the time to 00:00:00 for both dates
                                                        const currentDateOnly = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                                                        const sessionStartDateOnly = new Date(sessionStartDate.getFullYear(), sessionStartDate.getMonth(), sessionStartDate.getDate());

                                                        return (
                                                            <td align="center" className="pt-10" style={{
                                                                position: 'relative',
                                                                borderTop: 'none',
                                                                borderBottom: 'none',
                                                                width: `${100 / 7}%`,
                                                                height: '42px',
                                                            }}>
                                                                {currentDate < cutoffDate || currentDateOnly < sessionStartDateOnly ? (
                                                                    <div>&nbsp;</div>
                                                                ) : isExpired ? (
                                                                    <div className="expired">&nbsp;</div>
                                                                ) : session.days && session.days[currentFormattedDate] ? (
                                                                    parseInt(availability[sessionData.id][currentFormattedDate], 10) >= parseInt(quantity, 10) ? (
                                                                        <IconCheckBox
                                                                            name="booked[]"
                                                                            type="booked"
                                                                            value={encodeSessionData(event.id, session.days[currentFormattedDate].id, currentFormattedDate)}
                                                                            handleCheckboxChange={handleCheckboxChange}
                                                                            isChecked={selectedBooked.includes(encodeSessionData(event.id, session.days[currentFormattedDate].id, currentFormattedDate))}
                                                                        />
                                                                    ) : (
                                                                        <IconCheckBox
                                                                            name="waiting[]"
                                                                            type="waiting"
                                                                            value={encodeSessionData(event.id, session.days[currentFormattedDate].id, currentFormattedDate)}
                                                                            handleCheckboxChange={handleCheckboxChange}
                                                                            isChecked={selectedWaiting.includes(encodeSessionData(event.id, session.days[currentFormattedDate].id, currentFormattedDate))}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <div>&nbsp;</div>
                                                                )}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    )
                ))}
            </div>
            <div className="row highlighted-row">
                <div className="col-sm-6 hidden-xs">
                    <h4>Booking Fee</h4><small>(without insurance)</small>
                </div>
                <div className="col-sm-6 " align="right">
                    <h4 className="booking-fee">{formatter.format(bookingFee)}</h4>
                </div>
            </div>
            <div className="row pt-10 pull-right">
                <div className="col-md-12 no-padding-left no-padding-right">
                    <button disabled={!showCheckoutButton} onClick={handleCheckout} type="button" className="btn btn-primary">Proceed</button>
                </div>
            </div>
        </form>
    );
};

export default BookingForm;
