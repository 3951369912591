import React from "react";
import $ from 'jquery';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {authLogout} from "../../../modules/Admin/User/Login/store/actions";
import AdminAccountInvoker from "./AdminAccountInvoker";
import TopBarSettings from "./TopBarSettings";
import { urlConfigs } from '../../../configs/config'

const StudentTopBar = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const companyId = useSelector(state => state.userMeta.meta.company_id);

    const logout = () => {
        const confirmed = window.confirm('Are you sure you want to log out?');

        if (confirmed) {
            dispatch(authLogout());
            let authHash = localStorage.getItem('authHash');
            localStorage.setItem('userToken', '');
            localStorage.setItem('authHash', '');
            history.replace("/admin/students/login?id=" + companyId + '&authhash=' + authHash);
        }
    };

    return (
        <div className="navbar navbar-inverse">
            <div className="navbar-header">
                <a className="navbar-brand" href={urlConfigs.LOCAL_URL}><img
                    src="/logo.png" alt=""/></a>

                <ul className="nav navbar-nav visible-xs-block">
                    <li><a data-toggle="collapse" data-target="#navbar-mobile"><i className="icon-tree5"/></a></li>
                    <li><a className="sidebar-mobile-main-toggle"><i className="icon-paragraph-justify3" /></a></li>
                    <li><a className="sidebar-mobile-opposite-toggle"><i className="icon-indent-decrease2" /></a></li>
                </ul>
            </div>

            <div className="navbar-collapse collapse" id="navbar-mobile">
                <ul className="nav navbar-nav">
                    <li><a className="sidebar-control sidebar-main-toggle hidden-xs"><i
                        className="icon-paragraph-justify3"/></a>
                    </li>
                </ul>

                <ul className="nav navbar-nav navbar-right navbar-top-settings">
                    <li>
                        <a className="btn-logout" onClick={logout}>
                            Logout
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    );
};

export default StudentTopBar;

export const hideMenuMobile = () => {
    const htmlBody = $('body');
    const mobileMenuMainClass = 'sidebar-mobile-main';
    const mobileMenuIndicatorClass = 'sidebar-xs-indicator';
    const isMobile = $(window).width() < 769;

    if (isMobile) {
        if (htmlBody.attr('class').includes(mobileMenuMainClass)) {
            htmlBody.removeClass(mobileMenuMainClass);
        }
        if (htmlBody.attr('class').includes(mobileMenuIndicatorClass)) {
            htmlBody.removeClass(mobileMenuIndicatorClass);
        }
    }
};
