import React from 'react'
import VenueDetails from './VenueDetails'

const EventInfo = ({ event, setStep, isAvailable }) => {
    if (!event) return <div>No event data available.</div>;

    const formatDate = (dateString) => {
        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    return (
        <div className="row">
            <div className="event-details">
                <div className="col-md-8">
                    <h1>{event.name}</h1>
                    <h5 className="text-grey">
                        From {formatDate(event.start_date)} to {formatDate(event.end_date)}
                    </h5>

                    {event.age_start > 0 || event.age_end < 100 ? (
                        <h5 className="text-grey">
                            Age {event.age_start > 0 && event.age_start}
                            {event.age_start > 0 && event.age_end < 100 ? ' to ' :
                                event.age_start === 0 && event.age_end < 100 ? ' up to ' :
                                    event.age_start > 0 && event.age_end === 100 ? ' onwards' : ''}
                            {event.age_end < 100 && event.age_end}
                        </h5>
                    ) : null}
                    <br/><br/>

                    <div dangerouslySetInnerHTML={{ __html: event.full }} />
                </div>

                <div className="col-md-4" style={{ marginTop: '20px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <tbody>
                                    <tr className="border-dashed">
                                        <td className="no-padding">
                                            <i className="icon-envelop3"></i>
                                        </td>
                                        <td>
                                            {event.Contacts.map((contact, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br/>}
                                                    <a href={`mailto:${contact.email}`}>Send Email ({contact.name})</a>
                                                </React.Fragment>
                                            ))}
                                        </td>
                                    </tr>
                                    <tr className="border-dashed">
                                        <td className="no-padding">
                                            <i className="icon-phone2"></i>
                                        </td>
                                        <td>
                                            {event.Contacts.map((contact, index) => (
                                                <React.Fragment key={index}>
                                                    {index > 0 && <br/>}
                                                    <a href={`tel:${contact.phone}`}>{contact.phone} ({contact.name})</a>
                                                </React.Fragment>
                                            ))}
                                        </td>
                                    </tr>

                                    {event.Venue && <tr className='border-dashed'>
                                        <td className="no-padding">
                                            <i className="icon-location3"></i>
                                        </td>
                                        <td>
                                            <VenueDetails venue={event.Venue} />
                                        </td>
                                    </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: event.intro }} />
                            {event?.Downloads?.length > 0 && (
                                <>
                                    <h4>Downloads</h4>
                                    {event.Downloads.map((download, index) => (
                                        <ul key={index}>
                                            <li>
                                                <a href={`${process.env.PUBLIC_URL}/media/downloads/${download.file}`} target="_blank">
                                                    {download.name}
                                                </a>
                                            </li>
                                        </ul>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-8" style={{ paddingBottom: '40px', marginTop: '20px' }}>
                    {!isAvailable && (
                        <div className="alert alert-danger" role="alert">
                            Currently, there are no available sessions for booking. Please back later.
                        </div>
                    )}
                    <button className="btn btn-primary" disabled={!isAvailable} onClick={() => {
                        setStep(2)
                    }}>Book Now
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EventInfo;
