import { useState } from '../../../library/base/baseContainerImports'
import { isEmpty } from 'lodash'

const CouponForm = ({addCoupon, loading, coupon, addNSWCoupon, nswLoading, nswCoupon}) => {
    const [couponCode, setCouponCode] = useState('');
    const [nswCouponCode, setNSWCouponCode] = useState('');

    const handleChange = (event) => {
        setCouponCode(event.target.value);
    };

    const handleChangeNSW = (event) => {
        setNSWCouponCode(event.target.value);
    };

    return (
        <>
            <div>
                <h6>If you have a coupon code, please enter here and click Apply button.</h6>
            </div>
            <div className="row mt-10">
                <div className="form-coupon col-md-12"/>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            id="inputCoupon"
                            placeholder="Enter Coupon"
                            disabled={loading || !isEmpty(nswCoupon)}
                            defaultValue={nswCoupon ? '' : coupon}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-2"
                        onClick={() => {
                            addCoupon(couponCode)
                        }}
                        disabled={loading || !isEmpty(nswCoupon)}
                    >
                        Apply {(nswCoupon ? false : !isEmpty(coupon)) && (<i className="icon-check"/>)}
                    </button>
                </div>
            </div>

            <div>
                <h6>Redeeming the vouchers</h6>
            </div>
            <div className="row mt-10">
                <div className="form-nsw-coupon col-md-12"/>
                <div className="col-md-4">
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={handleChangeNSW}
                            id="inputCoupon"
                            placeholder="Enter NSW Voucher"
                            disabled={nswLoading || !isEmpty(coupon)}
                            defaultValue={nswCoupon}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <button
                        type="button"
                        className="btn btn-primary mb-2"
                        onClick={() => {
                            addNSWCoupon(nswCouponCode)
                        }}
                        disabled={nswLoading || !isEmpty(coupon)}
                    >
                        Redeem {nswCoupon && (<i className="icon-check"/>)}
                    </button>
                </div>
            </div>
        </>
    );
}

export default CouponForm;