import { urlConfigs, useState } from '../../../library/base/baseContainerImports'
import React from 'react'

const BirthDayPartyTermsAndConditionsForm = ({setAcceptTermAndCondition, acceptTermAndCondition, paymentTerms}) => {
    const [showTerm, setShowTerm] = useState('');

    return (
        <>
            <div>
                <h6>Terms and Conditions</h6>
            </div>
            <div className="row mt-10">
                <fieldset id="fieldset-paymentdetails" className=" col-md-12">
                    <div className="row p-5">
                        <div className="credit-card-description col-md-6">
                            <p ><small>All Credit Card Payments will be processed via Stripe Payment Gateway.</small></p>
                            <div>
                                <img src={`${urlConfigs.BASE_URL}/images/cc-logo.jpg`} width="115"  alt="cc"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-row form-group">
                                <label htmlFor="terms">
                                    Do you accept our Terms and Conditions? (<a onClick={() => setShowTerm(! showTerm)}>Click
                                    here to read</a>)
                                </label>
                                <div className="radio">
                                    <label htmlFor="terms-0">
                                        <input className="input-checkbox "
                                               type="radio" name="terms"
                                               id="terms-0"
                                               checked={! acceptTermAndCondition}
                                               onChange={() => setAcceptTermAndCondition(false)}
                                        />No
                                    </label>
                                </div>
                                <div className="radio">
                                    <label htmlFor="terms-1">
                                        <input className="input-checkbox "
                                               type="radio" name="terms"
                                               checked={acceptTermAndCondition}
                                               id="terms-1" onChange={() => setAcceptTermAndCondition(true)}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="row">
            <div className="col-md-12">
                    {showTerm && (
                        <fieldset id="terms-conditions">
                            <div className="col-md-12">
                                <div dangerouslySetInnerHTML={{ __html: paymentTerms }} />
                            </div>
                        </fieldset>
                    )}
                </div>
            </div>
        </>
    );
}

export default BirthDayPartyTermsAndConditionsForm;