import Loading from './Components/Loading'
import React from 'react'
import {
    $,
    queryString,
    urlConfigs,
    useActionUrl,
    useEffect, useFormInit,
    useLocation,
    useState,
    showMessage, showUserMessage,
} from '../../library/base/baseContainerImports'
import Form from '../../library/basic/forms/Form'

const PartyWaiver = () => {
    const [loading, setLoading] = useState(true);
    const [companyLogo, setCompanyLogo] = useState('');

    const location = useLocation();
    const {id, authhash} = queryString.parse(location.search);

    const {getFormActions} = useActionUrl();

    const formAfterPopulate = () => {
        $("#check_waiver").click(function(){
            const formData = $(".form_waiver").serialize();
            const urlCheck = urlConfigs.BASE_URL + '/api/v1/event-booking/waiver/check?authhash=' + authhash
            $.post(urlCheck, formData, function (result){
                if (!result.valid){
                    showMessage( $(".activate_message") , result.message );
                    return false;
                }else{
                    if ($(".nNote").length){
                        $(".nNote").remove();
                    }
                    $(".signup-area").hide();
                    $(".waiver_validator").remove();
                    $(".waiver_info").slideDown();
                }
            });

            return false;
        });

        $(".submit_waiver").click(function(){
            $("form.form_waiver").processForm({
                mode: "callback", callback: function (valid, response) {
                    if (valid) {
                        showUserMessage (
                            "Waiver Form Successfully Submitted!",
                            "We located your previously signed Waiver and therefore, you do not have to sign it again.",
                            1) ;
                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                        return false;
                    } else {
                        showUserMessage (
                            "Waiver Form Submission Failed!",
                            "We encountered a technical issue and please try again.",
                            2) ;
                        return false;
                    }
                }
            });
        });

        $('#student_id').on('change', function () {
            const student_id = $(this).val();
            const url = `${urlConfigs.BASE_URL}/api/v1/event-booking/waiver/child-info?id=${id}&authhash=${authhash}&child_id=${student_id}`;
            $.get(url, function (result){
                $.each(result, function (key, value) {
                    $('#' + key).val(value)
                })
            });
        })
    }

    const formId = "form-event-waiver-data";
    const {formAction, formDefinitionAction} = getFormActions('/api/v1/event-booking/waiver', {authhash, id});
    const {fetchFormData} = useFormInit({formId, formAction, formAfterPopulate});

    const companyBranding = () => {
        setLoading(true)
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
            setLoading(false)
        });
    };

    useEffect(() => {
        companyBranding();
    }, []);

    return (
        <div className="waiver">
            <div className="container">
                {loading ? (<Loading/>) : (
                    <div
                        className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10 text-center">
                        {companyLogo && <img alt="Logo" src={companyLogo}/>}
                    </div>
                )}

                <div>
                    <div className="activate_message"></div>
                    <Form
                        id={formId}
                        action={formAction}
                        formClass="form_data generic_form form_waiver"
                        encType="application/x-www-form-urlencoded"
                        jsonLoadAction={formDefinitionAction}
                        jsonLoadActionHandler={fetchFormData}
                    />
                </div>
            </div>
        </div>
    )
}

export default PartyWaiver
