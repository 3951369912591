import { urlConfigs, useState } from '../../../library/base/baseContainerImports'
import React from 'react'

const TermAndConditionsForm = ({termContent, setAcceptTermAndCondition, acceptTermAndCondition}) => {
    const [showTerm, setShowTerm] = useState(false);

    const showHandle = (e) => {
        e.preventDefault()
        setShowTerm(!showTerm)
    }

    return (
        <>
            <div>
                <h6>Terms and Conditions</h6>
            </div>
            <div className="row mt-10">
                <fieldset id="fieldset-paymentdetails" className=" col-md-12">
                    <div className="row p-5">
                        <div className="credit-card-description col-md-6">
                            <p ><small>All Credit Card Payments will be processed via Stripe Payment Gateway.</small></p>
                            <div>
                                <img src={`${urlConfigs.BASE_URL}/images/cc-logo.jpg`} width="115"  alt="cc"/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-row form-group">
                                <label htmlFor="terms">
                                    Do you accept our Terms and Conditions? (<a href="#" onClick={showHandle}>Click here to read</a>)
                                </label>
                                <div className="radio">
                                    <label htmlFor="terms-0">
                                        <input className="input-checkbox "
                                           type="radio" name="terms"
                                           id="terms-0"
                                           checked={!acceptTermAndCondition}
                                           onChange={() => setAcceptTermAndCondition(false)}
                                        />No
                                    </label>
                                </div>
                                <div className="radio">
                                    <label htmlFor="terms-1">
                                        <input className="input-checkbox "
                                           type="radio" name="terms"
                                           checked={acceptTermAndCondition}
                                           id="terms-1" onChange={() => setAcceptTermAndCondition(true)}
                                        />Yes
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {showTerm && (
                        <fieldset id="terms-conditions">
                            <div className="col-md-12">
                                <h2>Our Terms and Conditions</h2>

                                <div className="row">
                                    <div className="col-md-12">
                                        {termContent ? <div dangerouslySetInnerHTML={{__html: termContent}} /> : <>
                                            <p>Payment must be made at the time of booking to secure your
                                            child's booking.</p>
                                            <p>Payments are non refundable for a change of mind.</p>
                                            <p>If the gym is closed due to unforeseen circumstances, you may
                                                postpone or request a refund for your payment.</p>
                                            <p>If your child is sick, a credit note can be provided so long as
                                                adequate notice is provided directly to the gym.</p>
                                            <p>For late pick up of your child/children (after 3.30pm) a fee of
                                                $15 per 15 minutes (or part thereof) will be charged to your
                                                booking credit card.</p>
                                        </>}
                                        
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    )}
                </div>
            </div>
        </>
    );
}

export default TermAndConditionsForm;
