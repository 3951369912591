/* eslint-disable */
import React from 'react';
import $ from 'jquery';
import { useDispatch, useSelector } from 'react-redux'
import {useHistory} from "react-router";
import {authLogout} from "../../modules/Admin/User/Login/store/actions";
import Roles from "../../modules/Company/Dashboards/Roles";

const useAjaxSetup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const role = useSelector(state => state.userMeta?.meta?.role);
    const companyId = useSelector(state => state.userMeta.meta.company_id);

    const prepareAjaxSetup = (token) => {

        $.ajaxSetup({
            beforeSend: (xhr) => {
                const userToken = token ? token : localStorage.getItem('userToken');
                if (userToken) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + userToken);
                } else {
                    xhr.setRequestHeader('Authorization', 'Bearer Public');
                }
            },
            complete: function (xhr) {
                if (xhr.status === 403) {   // Token is expired
                    dispatch(authLogout());
                    const fullUrl = window.location.href;
                    const url = new URL(fullUrl);
                    const urlAuthHash = url.searchParams.get("authhash");
                
                    let storedAuthHash = localStorage.getItem('authHash');
                    let authHash = storedAuthHash ? storedAuthHash : urlAuthHash;
                    localStorage.setItem('userToken', '');
                    localStorage.setItem('authHash', '');
                    localStorage.setItem('loginStatus', 'loggedOut');
                   
                    const currentPath = window.location.pathname;
                    if (currentPath === "/admin/students/login" || currentPath === "/admin/login") return
                    if(currentPath === "/birthday-party/update-guest" && authHash){
                        history.replace("/admin/students/login?authhash=" + authHash + '&redirect='+ encodeURIComponent(fullUrl));
                    }else if (role === Roles.Student && authHash && companyId) {
                        history.replace("/admin/students/login?id=" + companyId + '&authhash=' + authHash);
                    }  else {
                        history.replace("/admin/login");
                    }
                }
            }
        });

        $(document).ajaxStop(function(){
            const status = localStorage.getItem('loginStatus');

            if (status === 'loggedOut') {
                localStorage.setItem('loginStatus', '');
                alert('Your session has expired. Please login again.');
            }
        });
    };

    return {prepareAjaxSetup};
};

export default useAjaxSetup;
