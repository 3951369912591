import  {
    React, useLocation, CompanyRoutes, useEffect
}  from 'library/base/baseContainerImports';
import $ from "jquery";
import {useDispatch, useSelector} from "react-redux";
import useInitialize from "../library/hooks/useInitialize";
import '../css/cssImports';
import LayoutContainer from "../layouts/LayoutContainer";
import PublicContainer from "../layouts/public/PublicContainer";
import {authSuccess} from "../modules/Admin/User/Login/store/actions";
import useAjaxSetup from "../library/hooks/useAjaxSetup";

const App = () => {
    const initSuccess = useInitialize();
    const userToken = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const {prepareAjaxSetup} = useAjaxSetup();

    /**
     * This is where init functions are called - once per application load
     */
    useEffect(() => {
        console.log(initSuccess);

        setAjaxDefaults();
    }, []);

    const publishLayoutUrls = [
        "/booking",
        "/booking/checkout",
        "/event/waiver",
        "/birthday-party/booking",
        "/birthday-party/booking/checkout",
        "/birthday-party/waiver",
        "/event-booking/waiver",
        "/birthday-party/update-guest",
        "/events/invitation/confirm",
    ];

    const signupLayoutUrls = [
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.path ,
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.path + "/" ,
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.FINAL.path,
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.FINAL.path + "/",
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.CANCEL.path,
        CompanyRoutes.ADMIN.COMPANY_SIGNUP.CANCEL.path + "/",
        CompanyRoutes.ADMIN.PAYMENT_INVOICE.path,
        CompanyRoutes.ADMIN.PAYMENT_INVOICE.path + "/" ,
        CompanyRoutes.ADMIN.STUDENTS.SIGNUP.path,
        CompanyRoutes.ADMIN.STUDENTS.SIGNUP.path + "/",
        CompanyRoutes.ADMIN.STUDENTS.LOGIN.path,
        CompanyRoutes.ADMIN.STUDENTS.LOGIN.path + "/",
        CompanyRoutes.ADMIN.STUDENTS.FORGOTTEN_PASSWORD.path,
        CompanyRoutes.ADMIN.STUDENTS.FORGOTTEN_PASSWORD.path + "/",
        CompanyRoutes.ADMIN.STUDENTS.REQUEST_FORGOTTEN_PASSWORD.path,
        CompanyRoutes.ADMIN.STUDENTS.REQUEST_FORGOTTEN_PASSWORD.path + "/",
    ];
    const location = useLocation();

    const isLoggedIn = () => {
        if(publishLayoutUrls.indexOf(location.pathname) !== -1) {
            return false;
        }

        if(signupLayoutUrls.indexOf(location.pathname) !== -1) {
            return true;
        }
        return userToken;
    };

    const setAjaxDefaults = () => {
        prepareAjaxSetup();

        $(document).ajaxSuccess(function (event, request) {
            const newToken = request.getResponseHeader('token');
            if (newToken) {
                dispatch(authSuccess(newToken));
            }
        });
    };

    return (
        <>
            {isLoggedIn() ? <LayoutContainer/> : <PublicContainer/>}
        </>
    );
};

export default App;
