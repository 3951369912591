import React, { useState } from 'react';
import '../Css/CheckboxIcon.css'

const CheckboxIcon = ({name, type, value, handleCheckboxChange, isChecked}) => {

    const toggleCheckbox = (e) => {
        handleCheckboxChange(e.target.value, type)
    };

    const iconClass = () => {
        var className = type === 'booked' ? 'icon-check custom-icon' : 'icon-alarm  custom-icon'
        className += isChecked ? (type === 'booked' ? ' icon-booked icon-checked' : ' icon-unbooked icon-checked') : ' icon-unchecked';
        return className;
    }

    return (
        <label className="custom-label">
            <input
                type="checkbox"
                checked={isChecked}
                onChange={toggleCheckbox}
                className="custom-input"
                name={name}
                value={value}
            />
            <i className={iconClass()} />
        </label>
    );
};

export default CheckboxIcon;
