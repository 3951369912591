import  {
    React, useLocation, CompanyRoutes
}  from 'library/base/baseContainerImports';
import DualSideBarLayout from "./DualSideBarLayout";
import LeftMenuTopBarLayout from "./LeftMenuTopBarLayout";
import SignupLayout from "./SignupLayout";
import StudentSideBarLayout from './StudentSideBarLayout'
import { useSelector } from 'react-redux'

const sideBarLayoutUrls = [
    CompanyRoutes.ADMIN.REGISTRATION_FORM.ADD.path,
    CompanyRoutes.ADMIN.REGISTRATION_FORM.ADD.path + "/",
    CompanyRoutes.ADMIN.REGISTRATION_FORM.EDIT.path,
    CompanyRoutes.ADMIN.REGISTRATION_FORM.EDIT.path + "/"
];
const sideBarStudentLayoutUrls = [
    CompanyRoutes.ADMIN.STUDENTS.PROFILE.path,
    CompanyRoutes.ADMIN.STUDENTS.PROFILE.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.path,
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.VIEW.path,
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.VIEW.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.INVOICES.path,
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.INVOICES.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.INVOICES.VIEW.path,
    CompanyRoutes.ADMIN.STUDENTS.REGISTRATIONS.INVOICES.VIEW.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.TASK.path,
    CompanyRoutes.ADMIN.STUDENTS.TASK.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.EVENT.path,
    CompanyRoutes.ADMIN.STUDENTS.EVENT.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.MY_BOOKING.path,
    CompanyRoutes.ADMIN.STUDENTS.MY_BOOKING.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.MY_BOOKING.VIEW.path,
    CompanyRoutes.ADMIN.STUDENTS.MY_BOOKING.VIEW.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.MY_PARTY_BOOKING.VIEW.path,
    CompanyRoutes.ADMIN.STUDENTS.MY_PARTY_BOOKING.VIEW.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.MY_PARTY_BOOKING.UPDATE_GUEST.path,
    CompanyRoutes.ADMIN.STUDENTS.MY_PARTY_BOOKING.UPDATE_GUEST.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.PAYMENT.path,
    CompanyRoutes.ADMIN.STUDENTS.PAYMENT.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.PRIVATE_CLASSES.path,
    CompanyRoutes.ADMIN.STUDENTS.PRIVATE_CLASSES.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.PRIVATE_CLASSES.ADD.path,
    CompanyRoutes.ADMIN.STUDENTS.PRIVATE_CLASSES.ADD.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.ABSENCE_DAY.path,
    CompanyRoutes.ADMIN.STUDENTS.ABSENCE_DAY.path + "/",
];

const signupLayoutUrls = [
    CompanyRoutes.ADMIN.COMPANY_SIGNUP.path ,
    CompanyRoutes.ADMIN.COMPANY_SIGNUP.path + "/" ,
    CompanyRoutes.ADMIN.COMPANY_SIGNUP.FINAL.path ,
    CompanyRoutes.ADMIN.COMPANY_SIGNUP.FINAL.path + "/" ,
    CompanyRoutes.ADMIN.PAYMENT_INVOICE.path,
    CompanyRoutes.ADMIN.PAYMENT_INVOICE.path + "/" ,
    CompanyRoutes.ADMIN.STUDENTS.SIGNUP.path,
    CompanyRoutes.ADMIN.STUDENTS.SIGNUP.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.LOGIN.path,
    CompanyRoutes.ADMIN.STUDENTS.LOGIN.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.FORGOTTEN_PASSWORD.path,
    CompanyRoutes.ADMIN.STUDENTS.FORGOTTEN_PASSWORD.path + "/",
    CompanyRoutes.ADMIN.STUDENTS.REQUEST_FORGOTTEN_PASSWORD.path,
    CompanyRoutes.ADMIN.STUDENTS.REQUEST_FORGOTTEN_PASSWORD.path + "/",
];

const LayoutContainer = () => {
    const role = useSelector(state => state.userMeta?.meta?.role);
    const location = useLocation();
    if (sideBarLayoutUrls.indexOf(location.pathname) !== -1) {
        return <DualSideBarLayout/>;
    }else if(signupLayoutUrls.indexOf(location.pathname) !== -1){
        return <SignupLayout/>;
    }else if(sideBarStudentLayoutUrls.indexOf(location.pathname) !== -1){
        return <StudentSideBarLayout/>;
    }else{
        return <LeftMenuTopBarLayout/>;
    }
};

export default LayoutContainer;
