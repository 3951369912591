import React from 'react'

const Loading = () => {
    return (
        <span
            className={'loading-circle input-group-addon border-white border-lg'}>
                    <i className="icon-spinner spinner"></i>
        </span>
    )
}

export default Loading;