import React from 'react';
import Signup from '../../modules/Admin/User/Signup/Signup';
import Login from '../../modules/Admin/User/Login/login';
import ForgottenPassword from 'modules/Admin/User/ForgottenPassword/ForgottenPassword'
import ResetPassword from 'modules/Admin/User/ResetPassword/ResetPassword'
import ActivateAccount from 'modules/Admin/User/ActivateAccount/ActivateAccount'
import EventBooking from 'modules/Booking/EventBooking'
import Checkout from 'modules/Booking/Checkout'
import PublicTopBar from "../layoutComponents/PublicTopBar";
import {Route, Switch} from "react-router";
import BirthdayPartyBooking from '../../modules/Booking/BirthdayPartyBooking'
import BirthdayPartyCheckout from '../../modules/Booking/BirthdayPartyCheckout'
import PartyWaiver from '../../modules/Booking/PartyWaiver'
import EventWaiver from '../../modules/Booking/EventWaiver'
import UpdatePartyGuest from '../../modules/Booking/UpdarePartyGuest'
import EventConfirmInvitation from '../../modules/Booking/EventConfirmInvitation'

/**
 * This layout will load Public pages
 */
const PublicContainer = () => {
    return (
        <>
            <PublicTopBar/>
            <Switch>
                <Route path='/admin/forgotten-password' exact={true} component={ForgottenPassword}/>
                <Route path='/admin/reset-password' exact={true} component={ResetPassword}/>
                <Route path='/admin/activate' exact={true} component={ActivateAccount}/>
                <Route path='/admin/login' exact={true} component={Login}/>
                <Route path='/booking' exact={true} component={EventBooking}/>
                <Route path='/booking/checkout' exact={true} component={Checkout}/>
                <Route path='/birthday-party/booking/' exact={true} component={BirthdayPartyBooking}/>
                <Route path='/birthday-party/booking/checkout/' exact={true} component={BirthdayPartyCheckout}/>
                <Route path='/birthday-party/waiver/' exact={true} component={PartyWaiver}/>
                <Route path='/birthday-party/update-guest/' exact={true} component={UpdatePartyGuest}/>
                <Route path='/event-booking/waiver/' exact={true} component={EventWaiver}/>
                <Route path='/events/invitation/confirm/' exact={true} component={EventConfirmInvitation}/>
                <Route component={Signup}/>
            </Switch>
        </>
    );
};

export default PublicContainer;
