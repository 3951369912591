import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {sendLoginRequest} from '../../../../service/request-handler';
import {authSuccess, authFail, authPending} from "./store/actions";
import useUserMeta from "../../../../library/hooks/useUserMeta";
import {queryString, useLocation} from "../../../../library/base/baseContainerImports";
import useAjaxSetup from 'library/hooks/useAjaxSetup';

const Login = ({ContainerClass}) => {
        const [user, setUser] = useState("");
        const [password, setPassword] = useState("");
        const [isLoginFailed, setIsLoginFailed] = useState(false);
        const [isActivationSuccessful, setIsActivationSuccessful] = useState(false);

        const history = useHistory();
        const dispatch = useDispatch();
        const {getUserMeta} = useUserMeta();
        const {prepareAjaxSetup} = useAjaxSetup();

        const location = useLocation();
        const {activation} = queryString.parse(location.search);

        const userLogin = () => {
            const headers = {'username': user, 'password': password};
            dispatch(authPending());
            sendLoginRequest(headers, onLoginSuccess, onLoginFail);
        };

        const onLoginSuccess = async (token) => {
            if (token) {
                setIsLoginFailed(false);

                await dispatch(authSuccess(token));
                const setToken = async function (key, value) {
                    await null;
                    return localStorage.setItem(key, value);
                };

                setToken('userToken', token).then(() => {
                    getUserMeta(token);
                });
                
                prepareAjaxSetup(token);

                history.push("/admin");
            }
        };

        const onLoginFail = (error) => {
            setIsLoginFailed(true);
            dispatch(authFail());
        };

        useEffect(() => {
            // window.load goes here
            if(activation === "1"){
                setIsActivationSuccessful(true);
            }
        }, []);

        const handleLogin = (e) => {
            e.preventDefault();
            e.stopPropagation();
            userLogin();
        };

        return (
            <>
                <div className={"page-container login-container " + ContainerClass}>
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="content">
                                <div className="panel panel-body login-form">
                                    <div className="text-center logo-block">
                                        <div className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10">
                                            <img src="/logo.png"/></div>
                                        <h5 className="content-group">Login to your account <small
                                            className="display-block">Your
                                            credentials</small></h5>
                                    </div>
                                    {isLoginFailed && <div className="alert alert-danger alert-styled-left alert-bordered" style={{fontWeight: 'normal'}}>
                                        <span className="text-semibold">Sorry! </span>
                                        We could not sign you in with the
                                        supplied username and password. Please try again.
                                    </div>}

                                    {isActivationSuccessful && <div className="alert alert-success alert-arrow-left alert-styled-left alert-bordered" style={{fontWeight: 'normal'}}>
                                        <span className="text-semibold">Success!</span>
                                        You have confirmed your email address. We will notify you when your account is active.
                                    </div>}

                                    <form enctype="application/x-www-form-urlencoded" action="/admin/login"
                                          className="generic_form form_login" method="post" onSubmit={handleLogin}>
                                        <dl className="zend_form">
                                            <div className="form-group">


                                                <div className="form-group has-feedback has-feedback-left">
                                                    <input
                                                        type="text"
                                                        id="login"
                                                        name="login"
                                                        className="form-control"
                                                        required="1"
                                                        value={user}
                                                        onChange={e => setUser(e.target.value)}
                                                        placeholder="Email Address *"

                                                    />

                                                    <div className="form-control-feedback">
                                                        <i className="icon-user text-muted"></i>
                                                    </div>
                                                </div>


                                            </div>
                                            <div className="form-group">

                                                <div className="form-group has-feedback has-feedback-left">

                                                    <input
                                                        type="password"
                                                        id="pass"
                                                        name="pass"
                                                        value={password}
                                                        required="1"
                                                        onChange={e => setPassword(e.target.value)}
                                                        className="form-control"
                                                        placeholder="Password *"
                                                    />


                                                    <div className="form-control-feedback">
                                                        <i className="icon-key text-muted"></i>
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="form-group">

                                                <button name="signin" id="signin" type="submit" className="btn btn-primary display-block full-width">
                                                    Sign In
                                                </button>

                                            </div>
                                        </dl>
                                    </form>

                                    <div class="form-group login-options">
                                        <div class="row">
                                            <div class="col-sm-12 text-center">
                                                <a href="/admin/forgotten-password">Forgot your password?</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="footer text-muted">
                                    &copy; {new Date().getFullYear()}. <a href="https://teamworksoftware.com.au/">Team Works</a>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>



            </>
        );
    }
;

export default Login;
