import React, { useEffect } from 'react'
import {
    $,
    queryString,
    urlConfigs, useActionUrl,
    useLocation,
    useState
} from '../../library/base/baseContainerImports'
import Loading from './Components/Loading'
import './Css/BirthdayPartyBooking.css'
import { useHistory } from 'react-router-dom'

const EventConfirmInvitation = () => {
    const location = useLocation();
    const {authhash, w, e, b} = queryString.parse(location.search);
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [companyLogo, setCompanyLogo] = useState('');
    const {getFullUrl} = useActionUrl();

    const companyBranding = () => {
        setLoading(true)
        const formAction    = urlConfigs.BASE_URL + '/rest/acl?authhash=' + authhash;
        $.get(formAction, function (data){
            if (data?.meta?.logo) {
                setCompanyLogo(data.meta.logo);
            }
            setLoading(false)
        });
    };

    const check = () => {
        setButtonLoading(true);
        const checkUrl = getFullUrl('/api/v1/events/wait-list/invitation/check', {authhash, w, e, b})
        $.get(checkUrl, function (data) {
            setStatus(data.valid);
            setButtonLoading(false);
        }).fail(() => {
            setButtonLoading(false);
        });
    }

    useEffect(() => {
        companyBranding();
        check();
    }, []);

    const accept = () => {
        setButtonLoading(true);
        const acceptUrl = getFullUrl('/api/v1/events/wait-list/invitation/accept', {authhash, w, e, b})
        $.get(acceptUrl, function (data) {
            if (data.valid) {
                history.push(`/booking?authhash=${authhash}&e=${e}&b=${b}`);
            } else {
                check();
            }
            setButtonLoading(false);
        }).fail(() => {
            setButtonLoading(false);
        });
    }

    const decline = () => {
        setButtonLoading(true);
        const acceptUrl = getFullUrl('/api/v1/events/wait-list/invitation/decline', {authhash, w, e, b})
        $.get(acceptUrl, function (data) {
            check();
            setButtonLoading(false);
        }).fail(() => {
            setButtonLoading(false);
        });
    }

    return (
        <div className="booking-event">
            <div className="container">
                {loading ? (<Loading />) :  (
                    <>
                        <div
                            className="display-block text-slate-300 border-bottom border-color-light-grey pb-10 mb-10 text-center">
                            {companyLogo && <img alt="Logo" src={companyLogo}/>}
                        </div>
                        {!status ? (
                            <div className="alert alert-success" role="alert">Invitation Confirmed!</div>
                        ) : (
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h4>Thank you for your patience on the waitlist!</h4>

                                    <p>We are pleased to let you know that a space has become available. You can accept
                                        or decline the spot by following the links below.</p>
                                </div>
                                <div className="col-md-12 text-center">
                                    <button onClick={accept} disabled={buttonLoading} className="btn btn-primary m-10">Accept</button>
                                    <button onClick={decline} disabled={buttonLoading} className="btn btn-danger m-10">Decline</button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default EventConfirmInvitation;
