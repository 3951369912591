import React from 'react';

const VenueDetails = ({ venue }) => {
  const mapLink = `https://maps.google.com/?q=${encodeURIComponent(
    `${venue.name}, ${venue.address}, ${venue.suburb} ${venue.postcode}, Australia`
  )}&t=m`;

  return (
    <div>
      <div>{venue.name}</div>
      <div>{venue.address}</div>
      <div>
        {venue.suburb} {venue.postcode}
      </div>
      <a href={mapLink} target="_blank" rel="noopener noreferrer">
        (View Map)
      </a>
    </div>
  );
};

export default VenueDetails;
